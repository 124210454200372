@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;700&display=swap);
html,
body {
	overscroll-behavior-x: none;
}

.ant-layout {
	background-color: #f6f8fB !important
}

.ant-page-header {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.10)
}

.ant-modal-body .ant-page-header {
	box-shadow: 0 0 0 !important
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
.button,
.ant-card,
input {
	font-family: 'Lato', sans-serif;
}

h1,
h2,
h3 {
	font-weight: 700;
}

.button {
	font-weight: 400;
}

.hljs {
	background-color: rgba(0, 0, 0, .1);
	border: 1px solid rgba(0, 0, 0, .6);
	font-size: 14px;
	border-radius: 5px;
	width: 100%;
	padding: 10px;
}

.logo {
	/* height: 32px; */
	/* background: rgba(255, 255, 255, 0.2); */
	margin: 16px;
	/* padding-left: 16px */
}

.pageheader-button {
	display: inline-block;
	vertical-align: text-top;
	margin-right: 32px;
}

.pageheader-button>img {
	margin-right: 8px;
}

.ant-card {
	border-radius: 3px
}



::-webkit-input-placeholder {
	opacity: 0.7
}



::-moz-placeholder {
	opacity: 0.7
}



:-ms-input-placeholder {
	opacity: 0.7
}



::-ms-input-placeholder {
	opacity: 0.7
}



::placeholder {
	opacity: 0.7
}

.workflowTable {
	vertical-align: top !important
}

.workflowCard {
	border-radius: 5px;
	padding: 10;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 300px;
	height: 350px;
	margin: 10px;
}

/* td {
	border: 1px solid blue
}
tr {
	border: 1px solid red;
} */

.workflowCard {
	border-radius: 15px;
	border: none;
	width: 350px;
	margin: 20px;
	/* -webkit-box-shadow:0 0 30px rgba(0, 0, 0, 0.1);
	-moz-box-shadow:0 0 30px rgba(0, 0, 0, 0.1);
	box-shadow:0 0 30px rgba(0, 0, 0, 0.1); */

	background: #ffffff;
box-shadow:  35px 35px 70px #d9d9d9, 
			 -35px -35px 70px #ffffff;
}

.workflowCard:hover {
	/* -webkit-box-shadow: 0 0 30px rgba(0, 0, 150, 0.15);
	-moz-box-shadow: 0 0 30px rgba(0, 0, 150, 0.15);
	box-shadow: 0 0 30px rgba(0, 0, 150, 0.15); */
	background: #ffffff;
box-shadow:  35px 35px 70px #d9d9d9, 
             -35px -35px 70px #ffffff;
}

.ant-card-head-title,
.ant-card-meta-title {
	font-weight: 800;
	font-size: 18px;
	/* white-space: normal; */
	/* text-overflow: ellipsis; */
}

.nowrapcol {
	white-space: nowrap;
}

.ant-form-item-label {
	font-size: 18px;
	font-weight: 700;
}

.ant-menu-horizontal {
	line-height: 66px;
	margin-top: -20px;
}

div.ant-typography-upd, .ant-typography-upd p {
    margin-bottom: 0;
}


tr, th, td {
    margin: auto;
    padding: auto;
    border: none;
	vertical-align: top !important;
} 


.euiPanel--paddingMedium {
    padding: 16px;
}

.euiPanel {
    box-shadow: 0 0.7px 1.4px rgba(0,0,0,0.07), 0 1.9px 4px rgba(0,0,0,0.05), 0 4.5px 10px rgba(0,0,0,0.05), 0 15px 32px rgba(0,0,0,0.04);
    background-color: #fff;
    border: none;
    border-radius: 6px;
    flex-grow: 1;
	
	font-family: 'Fira Code', monospace !important;
}


.euiCodeBlock.euiCodeBlock--inline {
    display: inline-block;
    white-space: pre;
    color: #343741;
    font-size: 90%;
    padding: 0 8px;
    background: #F5F7FA;
}

.euiCodeBlock.euiCodeBlock--fontSmall {
    font-size: 12px;
}

.euiCodeBlock--inline {
    border-radius: 4px;
}

.euiCodeBlock {
    max-width: 100%;
    display: block;
    position: relative;
    background: #F5F7FA;
	color: #343741;
}

.euiPanel.euiPanel--isClickable {
    display: block;
    width: 100%;
    text-align: left;
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	text-align: center;
	font-size: 22
}

.euiPanel.euiPanel--isClickable:hover, .euiPanel.euiPanel--isClickable:focus {
    box-shadow: 0 1px 5px rgba(0,0,0,0.1), 0 3.6px 13px rgba(0,0,0,0.07), 0 8.4px 23px rgba(0,0,0,0.06), 0 23px 35px rgba(0,0,0,0.05);
    /* -webkit-transform: translateY(-2px); */
    /* transform: translateY(-2px); */
    cursor: pointer;
}
.euiBetaBadge {
  color: #54B399 !important;
  /* color: white !important */
}

.pink {
  background-color: #DD0A73;
  color: white;
}

.pink>.euiToolTip__arrow {
  background-color: #DD0A73 !important
}

.project-stat>.euiTitle {
  margin-bottom: 0 !important;
}

.project-stat>* {
  color: #333 !important;
}

.clickable-card {
  border-radius: 10px;
  display: flex;
  display: block;
  width: 100%;
  text-align: left;
  -webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
  transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.clickable-card:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 3.6px 13px rgba(0, 0, 0, 0.07), 0 8.4px 23px rgba(0, 0, 0, 0.06), 0 23px 35px rgba(0, 0, 0, 0.05);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  cursor: pointer;
}

table {
  /* border: 1px solid red; */
  /* padding: 5px */
}

tr {
  /* border: 1px solid orange; */
  /* padding: 5px */
}

td {
  /* border: 1px solid red; */
  /* padding: 5px */
}

.step-icon {
  display: inline !important;
  /* background-color: red; */
}

html,
body {
  overscroll-behavior-x: none;
}

.euiCard {
  border-radius: 10px;
}

.add-step {
  opacity: .7
}

.add-step:hover {
  opacity: .9
}


@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.fade-in-entry {
  -webkit-animation: .3s ease-in 0s 1 fadeInUp !important;
          animation: .3s ease-in 0s 1 fadeInUp !important;
  -webkit-transform: transale3d(0, 0, 0);
          transform: transale3d(0, 0, 0);
}














.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
}

.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}

.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}

.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}

.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}

.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}

.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}

.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}

.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ant-cascader-picker-label:hover+.ant-cascader-input {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}

.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}

.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ant-cascader-menu:first-child {
  border-radius: 4px 0 0 4px;
}

.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 4px 4px 0;
}

.ant-cascader-menu:only-child {
  border-radius: 4px;
}

.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-cascader-menu-item:hover {
  background: #e6f7ff;
}

.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fafafa;
}

.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}

.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
}

:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
:root .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}

.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #f5222d;
}

/* body {
  background-color: rebeccapurple !important;
  overflow: auto !important;
  height: 800px !important;
  width: 100% !important;
  display: flex !important;
  align-items: flex-start !important;
  padding: 20px !important;
  border: 5px solid black !important;
}*/

.sticky-block-content {
  width: 293px;
  position: relative;
  -webkit-transition: margin-top 0.3s;
  transition: margin-top 0.3s;
} 

body {
  line-height: 1.5 !important;
}
