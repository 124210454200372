@import '~antd/dist/antd.css';

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,800,900&display=swap');

html,
body {
	overscroll-behavior-x: none;
}

.ant-layout {
	background-color: #f6f8fB !important
}

.ant-page-header {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.10)
}

.ant-modal-body .ant-page-header {
	box-shadow: 0 0 0 !important
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
.button,
.ant-card,
input {
	font-family: 'Lato', sans-serif;
}

h1,
h2,
h3 {
	font-weight: 700;
}

.button {
	font-weight: 400;
}

.hljs {
	background-color: rgba(0, 0, 0, .1);
	border: 1px solid rgba(0, 0, 0, .6);
	font-size: 14px;
	border-radius: 5px;
	width: 100%;
	padding: 10px;
}

.logo {
	/* height: 32px; */
	/* background: rgba(255, 255, 255, 0.2); */
	margin: 16px;
	/* padding-left: 16px */
}

.pageheader-button {
	display: inline-block;
	vertical-align: text-top;
	margin-right: 32px;
}

.pageheader-button>img {
	margin-right: 8px;
}

.ant-card {
	border-radius: 3px
}



::placeholder {
	opacity: 0.7
}

.workflowTable {
	vertical-align: top !important
}

.workflowCard {
	border-radius: 5px;
	padding: 10;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 300px;
	height: 350px;
	margin: 10px;
}

/* td {
	border: 1px solid blue
}
tr {
	border: 1px solid red;
} */

.workflowCard {
	border-radius: 15px;
	border: none;
	width: 350px;
	margin: 20px;
	/* -webkit-box-shadow:0 0 30px rgba(0, 0, 0, 0.1);
	-moz-box-shadow:0 0 30px rgba(0, 0, 0, 0.1);
	box-shadow:0 0 30px rgba(0, 0, 0, 0.1); */

	background: #ffffff;
box-shadow:  35px 35px 70px #d9d9d9, 
			 -35px -35px 70px #ffffff;
}

.workflowCard:hover {
	/* -webkit-box-shadow: 0 0 30px rgba(0, 0, 150, 0.15);
	-moz-box-shadow: 0 0 30px rgba(0, 0, 150, 0.15);
	box-shadow: 0 0 30px rgba(0, 0, 150, 0.15); */
	background: #ffffff;
box-shadow:  35px 35px 70px #d9d9d9, 
             -35px -35px 70px #ffffff;
}

.ant-card-head-title,
.ant-card-meta-title {
	font-weight: 800;
	font-size: 18px;
	/* white-space: normal; */
	/* text-overflow: ellipsis; */
}

.nowrapcol {
	white-space: nowrap;
}

.ant-form-item-label {
	font-size: 18px;
	font-weight: 700;
}

.ant-menu-horizontal {
	line-height: 66px;
	margin-top: -20px;
}

div.ant-typography-upd, .ant-typography-upd p {
    margin-bottom: 0;
}



@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;700&display=swap');


tr, th, td {
    margin: auto;
    padding: auto;
    border: none;
	vertical-align: top !important;
} 


.euiPanel--paddingMedium {
    padding: 16px;
}

.euiPanel {
    box-shadow: 0 0.7px 1.4px rgba(0,0,0,0.07), 0 1.9px 4px rgba(0,0,0,0.05), 0 4.5px 10px rgba(0,0,0,0.05), 0 15px 32px rgba(0,0,0,0.04);
    background-color: #fff;
    border: none;
    border-radius: 6px;
    -webkit-flex-grow: 1;
    flex-grow: 1;
	
	font-family: 'Fira Code', monospace !important;
}


.euiCodeBlock.euiCodeBlock--inline {
    display: inline-block;
    white-space: pre;
    color: #343741;
    font-size: 90%;
    padding: 0 8px;
    background: #F5F7FA;
}

.euiCodeBlock.euiCodeBlock--fontSmall {
    font-size: 12px;
}

.euiCodeBlock--inline {
    border-radius: 4px;
}

.euiCodeBlock {
    max-width: 100%;
    display: block;
    position: relative;
    background: #F5F7FA;
	color: #343741;
}

.euiPanel.euiPanel--isClickable {
    display: block;
    width: 100%;
    text-align: left;
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	text-align: center;
	font-size: 22
}

.euiPanel.euiPanel--isClickable:hover, .euiPanel.euiPanel--isClickable:focus {
    box-shadow: 0 1px 5px rgba(0,0,0,0.1), 0 3.6px 13px rgba(0,0,0,0.07), 0 8.4px 23px rgba(0,0,0,0.06), 0 23px 35px rgba(0,0,0,0.05);
    /* -webkit-transform: translateY(-2px); */
    /* transform: translateY(-2px); */
    cursor: pointer;
}